import {Link, ScrollShadow} from "@nextui-org/react";
import { NEWS_TYPE } from '@/components/helpers/constant.js';

var _ = require('lodash');
import SharedServiceInstance from "@/services/shared-service";
import SharedService from "@/services/shared-service";

export default function MostViewPost({data}) {
    return (
        <div className="p-5 pb-0 !bg-background/80 dark:bg-background/20 !bg-white/85 !bg-cover !bg-[url('/webp/chanh-toa.webp')] !bg-blend-lighten h-full rounded-[5px]">
            <h1 className="text-2xl font-bold mb-5 text-primary text-center">{NEWS_TYPE.MOST_VIEW.name.toLocaleUpperCase()}</h1>

            <ScrollShadow hideScrollBar className="w-full h-[361px]">
                <div className="divide-y-1">
                    {
                        data.value.map((item, index) => {
                            if (index > 0 && index <= 10) {
                                return (
                                    <Link key={item.id} className="!text-link w-full py-3 text-left"
                                        href={SharedService.getPostPath(item)}
                                    >
                                        {item.title} - {SharedServiceInstance.formatISODate(item.created) || ''}
                                    </Link>
                                );
                            }
                        })
                    }
                </div>
            </ScrollShadow>

        </div>
    );
}